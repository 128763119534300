import { render, staticRenderFns } from "./bindPhone.vue?vue&type=template&id=4ae6b35c&scoped=true&"
import script from "./bindPhone.vue?vue&type=script&lang=js&"
export * from "./bindPhone.vue?vue&type=script&lang=js&"
import style0 from "./bindPhone.vue?vue&type=style&index=0&id=4ae6b35c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ae6b35c",
  null
  
)

export default component.exports