<template>
  <div class="content">
    <h1 class="logo">
      <img src="../assets/img/phone_logo.png" alt />
    </h1>
    <h2 class="tit">绑定手机号,下次快速登录</h2>
    <div class="inner">
      <div class="avator">
        <img :src="formPhone.avatar" alt="微信头像" />
        <p class="name">
          HI,
          <span>{{formPhone.nickName}}</span>
        </p>
      </div>
      <p class="tips">绑定手机，便于下次快速登录</p>
      <el-form
        v-if="statusType == 2"
        :label-position="labelPosition"
        label-width="auto"
        :model="formPhone"
        :rules="rules"
        ref="formPhone"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="formPhone.phone" clearable></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="smsCode">
          <el-row>
            <el-col :span="15">
              <el-input v-model="formPhone.smsCode" clearable></el-input>
            </el-col>
            <el-col :span="9">
              <div class="btn">
                <el-button v-if="showSecond" type="primary" @click="sendSmsHandler()" plain>获取验证码</el-button>
                <el-button v-else type="primary" plain>{{second}}s重新发送</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form-item>

        <!-- <el-button @click="cancelForm('formPhone')">取消</el-button> -->
      </el-form>
      <p class="send">
        一直收不到短信？
        <span @click="sendSmsHandler()">点此重发</span>
      </p>
      <div class="checkbox">
        <el-checkbox v-model="checked">
          <a href>我同意《xxx注册协议》</a>
        </el-checkbox>
      </div>
      <div class="login-btn">
        <el-button type="primary" @click="bingPhoneHandler('formPhone')">登录并绑定手机</el-button>
      </div>
      <!-- <div v-else v-loading.fullscreen.lock="fullscreenLoading">
        <span class="info">{{ errorMsg }}</span>
      </div>-->
      <p class="copyRight">—— castle会员中心登录平台 ——</p>
    </div>
  </div>
</template>

<script>
import * as ftVal from "@/common/fortressValidator.js";
import { sendSms } from "@/api/index.js";
import { loginBySerialNum, bindPhone } from "@/api/login.js";
import { base64decode } from "@/common/index.js";
import { userInfo } from "@/api/index.js";
export default {
  props: {},
  data () {
    return {
      checked: true,
      second: 60, //验证码倒计时
      showSecond: true,//是否显示倒计时
      fullscreenLoading: false,
      statusType: 1,
      serialNum: "",
      labelPosition: "right",
      formPhone: {
        phone: "",
        smsCode: "",
        type: 0,
        unionId: "",
        pcOpenId: "",
        avatar: "",
        nickName: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: ftVal.validatePhone, trigger: "blur" },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created () {
    this.statusType = this.$route.query.statusType;
    this.originUrl = this.$route.query.originUrl;
    if (this.originUrl) {
      this.originUrl = base64decode(this.originUrl);
    }
    if (this.statusType == 1) {
      this.fullscreenLoading = true;
      this.serialNum = this.$route.query.serialNum;
      if (this.serialNum) {
        this.login();
      } else {
        this.fullscreenLoading = false;
        this.$router.push({ name: "login" });
      }
    } else {
      this.formPhone.unionId = this.$route.query.unionId;
      if (!this.formPhone.unionId) {
        this.$router.push({ name: "login" });
      }
      this.formPhone.pcOpenId = this.$route.query.openId;
      this.formPhone.avatar = this.$route.query.headImgUrl;
      this.formPhone.nickName = this.$route.query.nickname;
    }
  },
  methods: {
    //发送验证码
    sendSmsHandler () {
      if (!this.formPhone.phone) {
        this.$message({
          message: "请填写手机号",
          type: "error",
        });
        return;
      }
      sendSms({
        mobile: this.formPhone.phone,
        type: this.formPhone.type,
      }).then((resp) => {
        if (resp.code == 0) {
          this.$message({
            type: "success",
            message: "验证码发送成功, 请注意查收!",
          });
          //按钮倒计时
          this.showSecond = false
          var interval = setInterval(() => {
            let times = --this.second
            this.second = times < 10 ? '0' + times : times //小于10秒补 0
          }, 1000)
          setTimeout(() => {
            clearInterval(interval)
            this.second = 60
            this.showSecond = true
          }, 60000)

        } else {
          this.$message({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //绑定手机
    bingPhoneHandler (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.checked) {
            this.$message({
              message: '请先同意勾选XXXX注册协议',
              type: "error",
            });
            return
          }
          bindPhone(this.formPhone).then((resp) => {
            if (resp.code == 0) {
              //保存用户信息
              this.$store.commit("setToken", resp.data.token);
              localStorage.setItem(
                "fortressExpiredTime",
                resp.data.expiredTime
              );
              localStorage.setItem(
                "fortressRefreshToken",
                resp.data.refreshToken
              );
              if (this.originUrl) {
                window.location.href = this.originUrl;
              } else {
                userInfo().then((resp) => {
                  this.$store.commit("setUserInfo", resp.data);
                  //登录成功后 保存token信息 并跳转到home页
                  this.$router.push({ name: "dashboard" });
                });
              }
            } else {
              this.formPhone.smsCode = "";
              this.$message({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //获取token
    login () {
      loginBySerialNum(this.serialNum).then((resp) => {
        if (resp.code == 0) {
          //保存用户信息
          this.$store.commit("setToken", resp.data.token);
          localStorage.setItem("fortressExpiredTime", resp.data.expiredTime);
          localStorage.setItem("fortressRefreshToken", resp.data.refreshToken);
          if (this.originUrl) {
            window.location.href = this.originUrl+"&serialNum="+resp.data.serialNum;
          } else {
            userInfo().then((resp) => {
              this.$store.commit("setUserInfo", resp.data);
              //登录成功后 保存token信息 并跳转到home页
              this.$router.push({ name: "dashboard" });
            });
          }
        } else {
          this.fullscreenLoading = false;
          this.errorMsg = "登录过期请重新扫码登录";
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 2000);
        }
      });
    },
    cancelForm () {
      this.$router.push({ name: "login" });
    },
  },
  components: {},
};
</script>

<style lang='scss' scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
  background: url('../assets/img/phone_bg.png');
  background-size: 100% 100%;
  .logo {
    position: absolute;
    left: 10%;
    top: 3%;
  }
  .tit {
    text-align: center;
    color: #fff;
    font-size: 24px;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    letter-spacing: 1px;
  }
  .inner {
    padding: 30px 110px;
    position: absolute;
    width: 620px;
    height: 500px;
    margin: 70px auto;
    top: 12%;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    border-top: 2px solid #53bfff;
    box-shadow: 0 6px 6px -6px #e7f0ff;
    .avator {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 20px;
        width: 62px;
        height: 62px;
        object-fit: cover;
        border-radius: 4px;
      }
      .name {
        font-size: 14px;
        color: #111;
        span {
          margin-left: 10px;
        }
      }
    }
    .tips {
      margin-bottom: 30px;
      font-size: 14px;
      color: #81859b;
      letter-spacing: 1px;
    }
    &::v-deep .el-form-item {
      padding: 5px 0;
      box-sizing: content-box;
    }
    &::v-deep .el-form-item,
    &::v-deep .el-form-item__label,
    &::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    &::v-deep .el-input__inner {
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .btn {
      width: 100%;
      &::v-deep .el-button {
        width: 96%;
        margin-left: 4%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &::v-deep .el-button--primary.is-plain,
      &::v-deep .el-button--primary.is-plain:hover {
        background: transparent;
        border-color: #409eff;
        color: #409eff;
      }
    }
    .send {
      margin-bottom: 30px;
      text-align: right;
      font-size: 14px;
      color: #c3c3c3;
      span {
        color: #409eff;
        text-decoration: underline;
      }
    }
    .checkbox {
      margin-bottom: 20px;
      a {
        color: #c3c3c3;
        font-size: 14px;
        font-weight: normal;
      }
    }
    .login-btn {
      margin: 0 auto;
      width: 250px;
      &::v-deep .el-button {
        width: 100%;
      }
    }
    .info {
      display: block;
      margin: 20px auto;
      text-align: center;
      font: 27px italic;
      color: blue;
    }
    .copyRight {
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
      color: #bcbcc7;
      font-size: 14px;
    }
  }
}
</style>
